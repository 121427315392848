// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import '../stylesheets/application.scss';

import { createPopper } from '@popperjs/core';
import "@kollegorna/cocoon-vanilla-js";

require("@rails/ujs").start()
const Turbolinks = require("turbolinks");
Turbolinks.start();
require("@rails/activestorage").start()
require("channels")
require("chartkick");
require("chart.js");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


document.addEventListener("turbolinks:load", function() {
  // change the form path to the value set on the data-action & data-method of the button
  const groupSelectionButtons = document.getElementsByClassName("groupSelectionButton");

  if (groupSelectionButtons.length !== 0) {
    for (let i = 0; i < groupSelectionButtons.length; i++) {

      groupSelectionButtons[i].addEventListener('click', function (e) {
        let form = document.getElementById('AvailableCorrectionRequestsForm');

        form.setAttribute('action', this.dataset.path.replace('REPLACE', this.dataset.id));
        form.setAttribute('method', 'POST');

        let text = document.createElement("input");
        text.setAttribute("type", "hidden");
        text.setAttribute("name", "_method");
        text.setAttribute("value", "patch");
        form.appendChild(text);
        form.submit();
      });
    }
  }

  // $(document).on('click', '.btn-group button', function (e) {
  //   $(this).addClass('active').parent().siblings().find('button').removeClass('active');
  // });

  // ONLY meant to handle one of these on a page at a time - add a loop to fix that
  const radioButtons = document.getElementsByClassName("btn-group-toggle")

  if (radioButtons.length) {
    const availableButtons = radioButtons[0].getElementsByClassName('btn')
    if (availableButtons.length) {
      for (let i = 0; i < availableButtons.length; i++) {
        availableButtons[i].addEventListener('click', function (e) {
          const buttons = this.parentElement.getElementsByClassName('btn')

          for (let b of buttons) {
            b.classList.remove('active', 'btn-primary')
            b.classList.add('btn-secondary')
          }
          this.classList.remove('btn-secondary')
          this.classList.add('active', 'btn-primary')
        })
      }
    }
  }

  const checkboxes = document.querySelectorAll("input[name='correction_request_ids[]']");

  for (let i = 0; i < checkboxes.length; i++) {

    checkboxes[i].addEventListener( 'change', function() {
      var newCRs = null

      if(this.checked) { // add to selected
        const existingCRs = JSON.parse(new URLSearchParams(window.location.search).get('correction_request_ids'))

        if (Array.isArray(existingCRs)) {
          newCRs = [...existingCRs, this.value]
        } else {
          newCRs = existingCRs ? [existingCRs, this.value] : [this.value]
        }
        Turbolinks.visit('/correction_requests/?' + new URLSearchParams({'correction_request_ids': JSON.stringify(newCRs) }))
      } else { // remove from selected
        const existingCRs = JSON.parse(new URLSearchParams(window.location.search).get('correction_request_ids'))

        if (Array.isArray(existingCRs)) {
          newCRs = existingCRs.filter(cr => cr !== this.value)
        } else {
          newCRs = existingCRs === this.value && null
          // no correction requests left - nav to base article
        }
        if (newCRs && newCRs.length)
          Turbolinks.visit('/correction_requests/?' + new URLSearchParams({'correction_request_ids': JSON.stringify(newCRs) }))
        else
          Turbolinks.visit('/articles/' + this.getAttribute('data-article-id'))
      }
    });
  }

  // const groupDeleteButtons = document.getElementsByClassName("groupDeletButton");
  //
  // if (groupDeleteButtons) {
  //   groupDeleteButtons[0].addEventListener('click', function (e) {
  //     if(window.confirm("Are you sure?")) {
  //       let form = document.getElementById('AvailableCorrectionRequestsForm');
  //
  //       form.setAttribute('action', this.dataset.path);
  //       form.setAttribute('method', 'POST');
  //
  //       let text = document.createElement("input");
  //       text.setAttribute("type", "hidden");
  //       text.setAttribute("name", "_method");
  //       text.setAttribute("value", "delete");
  //       form.appendChild(text);
  //
  //       form.submit();
  //     }
  //   });
  // }

  // show the add to group menu popper and hide if a click is off the popper
  // const addToGroupButton = document.getElementById('addToGroup');
  // if (addToGroupButton) {
  //   const body = document.getElementById('groupSelectionBody');
  //
  //   document.addEventListener('click', (e) => {
  //     const target = e.target;
  //     if (target === body) return;
  //
  //     if (target === addToGroupButton && !addToGroupButton.classList.contains('disabled')) {
  //       body.classList.remove('d-none');
  //       createPopper(addToGroupButton, body, {
  //         placement: 'right',
  //         modifiers: [
  //           {
  //             name: 'offset',
  //             options: {
  //               offset: [0, 25],
  //             }
  //           }
  //         ]
  //       })
  //     } else {
  //       body.classList.add('d-none');
  //     }
  //   });
  //
  //   // handle enable/disable of the add to group button if correction requests are selected or not
  //   const checkboxes = document.querySelectorAll("input[name='correction_request_ids[]']");
  //
  //   for (let i = 0; i < checkboxes.length; i++) {
  //
  //     checkboxes[i].addEventListener( 'change', function() {
  //       if(this.checked) {
  //         addToGroupButton.classList.remove('disabled')
  //       } else {
  //         // loop through the checkboxes to see if anything is checked
  //         let checked=false;
  //         for(let i=0; i < checkboxes.length; i++){
  //           if(checkboxes[i].checked) {
  //             checked = true;
  //           }
  //         }
  //         if (!checked) { // nothing is checked - disable button
  //           addToGroupButton.classList.add('disabled')
  //         }
  //       }
  //     });
  //   }
  // }

  // handle the filtering of the correction request on article#show and related pages - change page URL to include filter
  // param when the select is updated
  const filterSelect = document.getElementById('correction_requests_filter');
  if (filterSelect){
    filterSelect.addEventListener('change', ()=>{
      let selectedOption = filterSelect.options[filterSelect.selectedIndex];
      let url = window.location.href;
      if (url.indexOf('?') > -1){
        url += '&filter=' + selectedOption.text
      }else{
        url += '?filter=' + selectedOption.text
      }
      Turbolinks.visit(url)
    })
  }

  // Populate the notification message with accept or reject message from button data element
  const accept = document.getElementById("cannedAcceptMessageButton");
  const reject = document.getElementById("cannedRejectMessageButton");
  const message = document.getElementById("correction_notification_message");

  if ((accept || reject) && message) {
    accept.addEventListener("click", function (e) {
      message.value = accept.dataset.text
      message.focus()
    });

    reject.addEventListener("click", function (e) {
      message.value = reject.dataset.text
      message.focus()
    });
  }

  const insertButtons = document.getElementsByClassName('insertButton');

  function insertMessageAtCaret(text) {
    const textarea = document.getElementById('correction_notification_message')
    textarea.setRangeText(
      text,
      textarea.selectionStart,
      textarea.selectionEnd,
      'end'
    )
  }

  if(insertButtons) {
    for (let i = 0; i < insertButtons.length; i++) {
      insertButtons[i].addEventListener('click', function (e) {
        insertMessageAtCaret(this.dataset.text)
        e.preventDefault()
      });
    }
  }

  // test messages on notifications send to slack on button click
  const notificationTestButtons = document.getElementsByClassName("test-button");

  if (notificationTestButtons) {
    for (let i = 0; i < notificationTestButtons.length; i++) {

      notificationTestButtons[i].addEventListener('click', function (e) {
        const channelSelect = e.target.parentElement.parentElement.querySelectorAll(".slack_channel")[0]
        const channelText = channelSelect.options[channelSelect.selectedIndex].text

        testChannel(channelText)
      });
    }
  }

});

function getMetaValue(name) {
  const element = document.head.querySelector(`meta[name="${name}"]`)
  return element.getAttribute('content') // test env doesn't generate csrf meta
}

function testChannel(channel) {
  const publication_id = document.getElementById("publication_id").value;
  fetch(`/notifications/${publication_id}/test_channel`, {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'X-CSRF-Token': getMetaValue('csrf-token'),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      name: channel,
    }),
  })
}
